import Link from "next/link";

const LoginButton = () => {
  // TODO: LOGOUT FUNCTION
  return (
    <div className="button__login">
      <Link href="/login">LOGIN</Link>
    </div>
  );
};

export default LoginButton;
