import Link from "next/link";

const BrownButton = ({ content, children, link, maxWidth, active = true }) => {
  return (
    <Link href={`${active ? link : "#"}`}>
      <a className="button__brown button" style={{ maxWidth: maxWidth }}>
        {content?.length > 0 ? content : children}
      </a>
    </Link>
  );
};

export default BrownButton;
