import LoginButton from "../buttons/LoginButton";
import Image from "next/image";
import Link from "next/link";
import Logo from "../images/The_Boho_Market_Logo.png";

const LandingHeader = () => {
  return (
    <div className="header header-container ">
      <div className="wrapper-90 header__content">
        <div className="header__logo">
          <Link href="https://thebohomarket.com">
            <a rel="noopener noreferrer" target="_blank">
              <Image
                src={Logo}
                alt="logo"
                height="100"
                width="300"
                on
              />
            </a>
          </Link>
        </div>
        <div className="header__navbar">
          <LoginButton />
        </div>
      </div>
    </div>
  );
};

export default LandingHeader;
