import Image from "next/image";

const RightContent = () => {
  return (
    <div className="wrapper-form-landing">
      <div className="landing__image">
        <Image
          src="https://res.cloudinary.com/dkceblkn7/image/upload/v1645330382/boho%20app%20assets/background%20images/Group_342_3_kaymap.png"
          alt="various pictures of boho market"
          height="496"
          width="450"
        />
      </div>
    </div>
  );
};

export default RightContent;
